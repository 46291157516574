<template>
  <div style="border-radius: 28px; background-color: #ffffff;">
    <b-row v-if="showFilterRow">
      <b-col
        md="12"
        class="px-5 py-2"
      >

        <h4><span style="text-decoration: underline;">Filter Columns:</span> </h4>
        <v-select
          v-model="FilterSelected"
          class="d-inline-block w-100"
          :options="SelectedItemFilterOptions"
          placeholder="Remove columns you don’t want displayed."
          multiple
          :searchable="true"
          :clearable="true"
        />
      </b-col>
    </b-row>

    <general-table
      :api-url="APIURL"
      :add-type="addType"
      :delete-content="true"
      :block-content="false"
      :edit-content="true"
      :view-content="false"
      :selectable="false"
      :columns="filteredColumns"
      :type="type"
      :per-page="perPage"
      :add-component-name="addComponentName"
      :selectcertificate="true"
      :certificate-options="certificate"
      :select-subcertificate="true"
      :selected-item-search-options="SelectedItemSearchOptions"
      :search-and-select="true"
      :filter-columns="filterColumns"
    >

      <!-- certificate control -->

      <template #cell(certificate)="data">
        <slot
          name="show"
          v-bind="data"
        >
          <div>
            <span
              style="font-size: smaller;"
            >
              {{ data.item.certificate.ar }}
            </span>
            <span v-if="data.item.certificate.ar && data.item.certificate.en">
              -
            </span>
            <span
              style="font-size: smaller;"
            >
              {{ data.item.certificate.en }}
            </span>
          </div>
        </slot>
      </template>
      <!-- sub certificate control -->

      <template #cell(sub_certificate)="data">
        <div>
          <div v-if="role === 'super-accountant' || role === 'accountant'">
            <span
              style="font-size: smaller;"
            >
              {{ data.item.sub_certificate.ar }}
            </span>
            <span
              v-if="data.item.sub_certificate.ar && data.item.sub_certificate.en"
              style="font-size: smaller;"
            >
              -
            </span>
            <span
              style="font-size: smaller;"
            >
              {{ data.item.sub_certificate.en }}
            </span>
          </div>
          <div v-else>
            <div
              v-if="data.item.sub_certificate === null"
              v-b-popover.hover.topright="'edit sub certificate'"
              style="width: 100% !important; height: 24px !important"
              class="hover-pointer-empty text-center"
              @click="
                $router.push({
                  name: 'edit-sub-certificate',
                  params: { id: data.item.id }
                })
              "
            />
            <div
              v-b-popover.hover.topright="'edit sub certificate'"
              style="height: 100% !important; display: flex; align-items: center; justify-content: center;"
              class="hover-pointer text-center"
              @click="
                $router.push({
                  name: 'edit-sub-certificate',
                  params: { id: data.item.id }
                })
              "
            >
              <span
                style="font-size: smaller;"
              >
                {{ data.item.sub_certificate.ar }}
              </span>
              <span
                v-if="data.item.sub_certificate.ar && data.item.sub_certificate.en"
                style="font-size: smaller;"
              >
                -
              </span>
              <span
                style="font-size: smaller;"
              >
                {{ data.item.sub_certificate.en }}
              </span>
            </div>
          </div>
        </div>
      </template>
      <!-- Email Sended control -->

      <template #cell(email_sended)="data">
        <slot
          name="show"
          v-bind="data"
        >
          <div class="text-center">
            <span v-if="data.item.email_sended === 1">
              <feather-icon
                icon="CheckIcon"
                size="25"
                color="#02111b"
              />
            </span>

            <span v-else>
              <feather-icon
                icon="XIcon"
                size="25"
                color="red"
              />
            </span>
          </div>
        </slot>
      </template>
      <!-- Require Audit control -->

      <template #cell(require_audit)="data">
        <slot
          name="show"
          v-bind="data"
        >
          <div class="text-center">
            <span v-if="data.item.require_audit === 1">
              <feather-icon
                icon="CheckIcon"
                size="25"
                color="#02111b"
              />
            </span>

            <span
              v-else
            ><feather-icon
              icon="XIcon"
              size="25"
              color="red"
            /></span>
          </div>
        </slot>
      </template>
      <!-- Invoice control -->

      <template #cell(invoice)="data">
        <slot
          name="show"
          v-bind="data"
        >
          <div class="text-center">
            <span>
              <feather-icon
                v-b-popover.hover.topright="'show invoice'"
                icon="FileTextIcon"
                size="25"
                color="#02111b"
                style="cursor: pointer;"
                @click="
                  $router.push({
                    name: 'show-invoice',
                    params: { id: data.item.id }
                  })
                "
              />
            </span>
          </div>
        </slot>
      </template>
      <!-- Facility Name control -->

      <template #cell(facility_name)="data">
        <div
          v-if="data.item.facility_name === ''"
          v-b-popover.hover.topright="'show facility info'"
          style="width: 100% !important; height: 24px !important"
          class="hover-pointer-empty text-center"
          @click="
            $router.push({
              name: 'request-histories',
              params: { id: data.item.id }
            })
          "
        />
        <div
          v-b-popover.hover.topright="'show facility info'"
          class="hover-pointer text-center "
          style="height: 100% !important; display: flex; align-items: center; justify-content: center;"
          @click="
            $router.push({
              name: 'request-histories',
              params: { id: data.item.id }
            })
          "
        >

          <span
            style="font-size: smaller;"
            class="text-nowrap"
          >
            {{ data.item.facility_name.en }}
          </span>
          <span v-if="data.item.facility_name.en && data.item.facility_name.ar">
            -
          </span>
          <span
            style="font-size: smaller;"
            class="text-nowrap"
          >
            {{ data.item.facility_name.ar }}
          </span>
        </div>
      </template>

      <template #cell(categoryRequestNo)="data">
        <span
          style="font-size: smaller;"
          class="text-nowrap px-1"
        >
          {{ data.item.categoryRequestNo }}
        </span>
      </template>
      <template #cell(last_status_update)="data">
        <span
          style="font-size: smaller;"
          class="text-nowrap px-1"
        >
          {{ data.item.last_status_update }}
        </span>
      </template>
      <template #cell(payment_status)="data">
        <div v-if="role === 'process-manager'">
          <span
            style="font-size: smaller;"
            class="text-nowrap"
          >
            {{ data.item.payment_status }}
          </span>
        </div>
        <div v-else>
          <div
            v-b-popover.hover.topright="'+ add payment'"
            class="hover-pointer text-center "
            style="height: 100% !important; display: flex; align-items: center; justify-content: center;"
            @click="
              $router.push({
                name: 'edit-payment-status',
                params: { id: data.item.id }
              })
            "
          >
            <span
              style="font-size: smaller;"
              class="text-nowrap"
            >
              {{ data.item.payment_status }}
            </span>
          </div>
        </div>
      </template>
      <!-- Sticky Cell for Request Code -->
      <template #cell(request_code)="data">
        <a
          class="request-code-a"
          :href="
            `https://engineers.gulftic.com/orders/order/${data.item.id}?role=${accessRole}&name=${accessName}&email=${accessEmail}&token=${accessToken}`
          "
          target="_blank"
        >
          <div v-b-popover.hover.topright="'Go To Request'">
            <!-- @click="
            $router.push({
              name: 'request-histories',
              params: { id: data.item.id }
            })
          " -->

            <span style="font-size: smaller; text-wrap: nowrap">
              {{ data.item.request_code }}
            </span>
          </div>
        </a>
      </template>
      <!-- ----- test  -->
      <template #cell(offer)="data">
        <div
          v-if="data.item.offer === null"
          v-b-popover.hover.topright="'+ add offer'"
          style="width: 100% !important; height: 24px !important;font-size: smaller;"
          class="hover-pointer-empty text-center"
          @click="
            $router.push({
              name: 'set-offer',
              params: { id: data.item.id }
            })
          "
        />
        <div
          v-b-popover.hover.topright="'+ add offer'"
          class="hover-pointer text-center"
          style="height: 100% !important; display: flex; align-items: center; justify-content: center;font-size: smaller;"
          @click="
            $router.push({
              name: 'set-offer',
              params: { id: data.item.id }
            })
          "
        >
          <span v-if="data.item.offer.name || data.item.offer.amount">
            {{ data.item.offer.name }} - {{ data.item.offer.amount }} %
          </span>
        </div>
      </template>

      <template #cell(office)="data">
        <div
          v-if="data.item.office === null"
          v-b-popover.hover.topright="'+ add office'"
          style="width: 100% !important; height: 24px !important;font-size: smaller;"
          class="hover-pointer-empty text-center"
          @click="
            $router.push({
              name: 'set-office',
              params: { id: data.item.id }
            })
          "
        />
        <div
          v-b-popover.hover.topright="'+ add office'"
          class="hover-pointer text-center"
          style="height: 100% !important; display: flex; align-items: center; justify-content: center;font-size: smaller;"
          @click="
            $router.push({
              name: 'set-office',
              params: { id: data.item.id }
            })
          "
        >
          <span v-if="data.item.office">
            {{ data.item.office }}
          </span>
        </div>
      </template>

      <template #cell(first_reviewer)="data">
        <div
          v-if="data.item.first_reviewer === null"
          v-b-popover.hover.topright="'+ add first reviewer'"
          style="width: 100% !important; height: 24px !important"
          class="hover-pointer-empty text-center"
          @click="
            $router.push({
              name: 'assign-engineer',
              params: { id: data.item.id, type: 'first_reviewer' }
            })
          "
        />

        <div
          v-b-popover.hover.topright="'+ add first reviewer'"
          style="height: 100% !important; display: flex; align-items: center; justify-content: center;"
          class="hover-pointer text-center"
          @click="
            $router.push({
              name: 'assign-engineer',
              params: { id: data.item.id, type: 'first_reviewer' }
            })
          "
        >
          <span style="font-size: smaller;">
            {{ data.item.first_reviewer }}
          </span>
        </div>
      </template>

      <template #cell(second_reviewer)="data">
        <div
          v-if="data.item.second_reviewer === null"
          v-b-popover.hover.topright="'+ add second reviewer'"
          style="width: 100% !important; height: 24px !important"
          class="hover-pointer-empty text-center"
          @click="
            $router.push({
              name: 'assign-engineer',
              params: { id: data.item.id, type: 'second_reviewer' }
            })
          "
        />
        <div
          v-b-popover.hover.topright="'+ add second reviewer'"
          style="height: 100% !important; display: flex; align-items: center; justify-content: center;"
          class="hover-pointer text-center"
          @click="
            $router.push({
              name: 'assign-engineer',
              params: { id: data.item.id, type: 'second_reviewer' }
            })
          "
        >
          <span style="font-size: smaller;">
            {{ data.item.second_reviewer }}
          </span>
        </div>
      </template>

      <template #cell(application_reviewer)="data">
        <div
          v-if="data.item.application_reviewer === null"
          v-b-popover.hover.topright="'+ add application reviewer'"
          style="width: 100% !important; height: 24px !important"
          class="hover-pointer-empty text-center"
          @click="
            $router.push({
              name: 'assign-engineer',
              params: { id: data.item.id, type: 'application_reviewer' }
            })
          "
        />
        <div
          v-b-popover.hover.topright="'+ add application reviewer'"
          style="height: 100% !important; display: flex; align-items: center; justify-content: center;"
          class="hover-pointer text-center"
          @click="
            $router.push({
              name: 'assign-engineer',
              params: { id: data.item.id, type: 'application_reviewer' }
            })
          "
        >
          <span style="font-size: smaller;">
            {{ data.item.application_reviewer }}
          </span>
        </div>
      </template>
      <template #cell(requester)="data">
        <span
          v-if="data.item.delegator"
          style="font-size: smaller;"
        >
          {{ data.item.delegator.name }}
        </span>
      </template>

    </general-table>

  </div>
</template>

<script>
// import { title } from '@/@core/utils/filter'
// import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import VSelect from 'vue-select'
import axios from 'axios'
import GeneralTable from '@/components/general-table/GeneralTable.vue'
import decryptData from '@/store/auth/decryptData'

export default {
  components: { GeneralTable, VSelect },
  data() {
    return {
      accessRole: localStorage.getItem('accessRole') || '',
      accessToken: localStorage.getItem('accessToken') || '',
      accessName: localStorage.getItem('name') || '',
      accessEmail: localStorage.getItem('email') || '',
      APIURL: 'requests',
      viewContent: true,
      type: 'page',
      perPage: 50,
      filter: {},
      addType: 'Add Request',
      addComponentName: 'add-request',
      filterColumns: false,
      certificate: [],
      columns: [
        // { key: 'id', sortable: false },
        {
          key: 'request_code',
          stickyColumn: true,
          isRowHeader: true,
          label: 'Request Code',
        },
        { key: 'categoryRequestNo', label: 'category Req. No' },
        { key: 'first_reviewer', label: 'first reviewer' },
        { key: 'second_reviewer', label: 'second reviewer' },
        { key: 'application_reviewer', label: 'application reviewer' },
        { key: 'certificate', label: 'Certificate' },
        { key: 'sub_certificate', label: 'Sub Certificate' },
        { key: 'source_date', label: 'Allocation Date' },
        { key: 'last_status_update', label: 'Last Status Update' },
        { key: 'last_status', label: 'Last Status' },
        { key: 'application_status', label: 'Application Status' },
        { key: 'due_date', label: 'Due Date' },
        { key: 'source', label: 'Source' },
        { key: 'facility_name', label: 'Facility Name' },
        { key: 'delegator', label: 'Requester' },
        { key: 'payment_status', label: 'Payment Status' },
        { key: 'payment_method', label: 'P.Method' },
        { key: 'location', label: 'Country' },
        { key: 'office', label: 'Payment Country' },
        { key: 'offer', label: 'Offer' },
        { key: 'certificate_price', label: 'Certificate Price' },
        { key: 'tax', label: 'Tax' },
        { key: 'other_tax', label: 'Other Tax' },
        { key: 'final_price', label: 'Final Price' },
        { key: 'wallet_balance', label: 'Wallet Balance' },
        // { key: 'edit_payment_status', label: 'Edit Payment Status' },
        { key: 'require_audit', label: 'Require Audit' },
        { key: 'email_sended', label: 'Email Sended' },
        // { key: 'set_offer', label: 'Set Offer' },
        // { key: 'assign_engineer', label: 'Assign Engineer' },
        { key: 'invoice', label: 'Invoice' },
      ],
      FilterSelected: [],
      SelectedItemFilterOptions: [
        { value: 'categoryRequestNo', label: 'Category Request Number' },
        { value: 'first_reviewer', label: 'First Reviewer' },
        { value: 'second_reviewer', label: 'Second Reviewer' },
        { value: 'application_reviewer', label: 'Application Reviewer' },
        { value: 'certificate', label: 'Certificate' },
        { value: 'sub_certificate', label: 'Sub Certificate' },
        { value: 'source_date', label: 'Allocation Date' },
        { value: 'last_status_update', label: 'Last Status Update' },
        { value: 'last_status', label: 'Last Status' },
        { value: 'application_status', label: 'Application Status' },
        { value: 'due_date', label: 'Due Date' },
        { value: 'source', label: 'Source' },
        { value: 'facility_name', label: 'Facility Name' },
        { value: 'delegator', label: 'Requester' },
        { value: 'payment_status', label: 'Payment Status' },
        { value: 'payment_method', label: 'Payment Method' },
        { value: 'location', label: 'Country' },
        { value: 'office', label: 'Payment Country' },
        { value: 'offer', label: 'Offer' },
        { value: 'certificate_price', label: 'Certificate Price' },
        { value: 'tax', label: 'Tax' },
        { value: 'other_tax', label: 'Other Tax' },
        { value: 'final_price', label: 'Final Price' },
        { value: 'wallet_balance', label: 'Wallet Balance' },
        { value: 'require_audit', label: 'Require Audit' },
        { value: 'email_sended', label: 'Email Sended' },
        { value: 'invoice', label: 'Invoice' },
      ],
      SelectedItemSearchOptions: [
        { value: 'all', text: 'All Data' },
        { value: 'request_code', text: 'Request Code' },
        { value: 'categoryRequestNo', text: 'Category Request Number' },
        { value: 'source_date', text: 'Allocation Date' },
        { value: 'payment_status', text: 'Payment Status' },
        { value: 'payment_method', text: 'Payment Method' },
        { value: 'application_status', text: 'Application Status' },
        { value: 'location', text: 'Location' },
        { value: 'offer', text: 'Offer' },
        { value: 'due_date', text: 'Due Date' },
        { value: 'require_audit', text: 'Require Audit' },
        { value: 'source', text: 'Source' },
        { value: 'office', text: 'Office' },
        { value: 'facility_name', text: 'Facility Name' },
        { value: 'delegator', text: 'Requester' },
        { value: 'first_reviewer', text: 'First Reviewer' },
        { value: 'second_reviewer', text: 'Second Reviewer' },
        { value: 'application_reviewer', text: 'Application Reviewer' },
        { value: 'last_status_update', text: 'Last Status Update' },
        { value: 'last_status', text: 'Last Status' },
        { value: 'certificate_price', text: 'Certificate Price' },
        { value: 'tax', text: 'Tax' },
        { value: 'other_tax', text: 'Other Tax' },
        { value: 'final_price', text: 'Final Price' },
      ],
      role: localStorage.getItem('accessRole'),
    }
  },
  computed: {
    ...mapGetters('app', ['showFilterRow']),
    filteredColumns() {
      if (this.role === 'super-accountant' || this.role === 'accountant') {
        return this.columns.filter(column => [
          'id',
          'categoryRequestNo',
          'source_date',
          'request_code',
          'payment_status',
          'payment_method',
          'source',
          'certificate',
          'sub_certificate',
          'office',
          'offer',
          'location',
          'created_at',
          'final_price',
          'certificate_price',
          'wallet_balance',
          'facility_name',
          'facility',
          'invoice',
          'edit_payment_status',
          'requester',
        ].includes(column.key))
      }
      if (this.role === 'process-manager') {
        return this.columns.filter(column => [
          'id',
          'categoryRequestNo',
          'source_date',
          'request_code',
          'payment_status',
          'first_reviewer',
          'second_reviewer',
          'application_reviewer',
          'created_at',
          'source',
          'location',
          'certificate',
          'sub_certificate',
          'require_audit',
          'assign_engineer',
          'requester',
        ].includes(column.key))
      }
      if (this.role === 'markter') {
        return this.columns.filter(column => [
          'id',
          'categoryRequestNo',
          'source_date',
          'request_code',
          'payment_status',
          'payment_method',
          'location',
          'due_date',
          'source',
          'certificate',
          'sub_certificate',
          'offer',
          'created_at',
          'facility_name',
          'require_audit',
          'email_sended',
          'set_offer',
          'requester',
        ].includes(column.key))
      }
      if (!Array.isArray(this.FilterSelected) || this.FilterSelected.length === 0) {
        return this.columns
      }
      const selectedValues = this.FilterSelected.map(item => item.value)
      return this.columns.filter(column => !selectedValues.includes(column.key))
    },
    apiUrl() {
      const base = 'requests?'
      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      const queryParams = Object.entries(this.filter)
        .filter(([key, value]) => key && value)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')

      return `${base}${queryParams}`
    },
  },
  watch: {
    FilterSelected(newVal) {
      if (newVal && newVal.length === 0) {
        localStorage.removeItem('FilterSelected')
      } else {
        localStorage.setItem('FilterSelected', JSON.stringify(this.FilterSelected))
      }
    },
  },
  beforeMount() {
    const getFilterSelected = localStorage.getItem('FilterSelected')
    this.FilterSelected = JSON.parse(getFilterSelected)
  },
  mounted() {
    axios
      .get('certificate_group')
      .then(res => {
        const dataCertificate = decryptData(res.data.payload)?.data
          ?.certificates
        this.certificate = dataCertificate.map(item => ({
          value: item.id,
          text: `${item.name?.ar} - ${item.name?.en}`,
        }))
      })
  },
  // methods: {
  // },
}
</script>

<style lang="scss" scoped>
.hover-pointer:hover {
  color: white; /* Change the property you want to affect on hover */
  background-color: #40aaf7;
  cursor: pointer;
  padding: 11.8px 0px;
}
.request-code-a {
  cursor: text;
}
.request-code-a:hover {
  color: white; /* Change the property you want to affect on hover */
}
.hover-pointer-empty:hover {
  color: white; /* Change the property you want to affect on hover */
  background-color: #40aaf7;
  cursor: pointer;
  padding: 20.2px 0px;
}
</style>
